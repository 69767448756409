import { textTransform } from "@mui/system";
import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "reactstrap";
import api from "../../Axios";
const Reports = () => {
  const [report, setReport] = useState([]);
  useEffect(() => {
    api.get("/admin/allUsers/viewAllReports").then((res) => {
      setReport(res.data.data);

      console.log(res.data.data);
    });
  }, []);
  return (
    <Row>
      <Col>
        <div className="container-fluid p-3 mt-5">
          <section className="box ">
            <table class="table table-sm mt-3">
              <thead
                class="thead"
                style={{ backgroundColor: "black", color: "white" }}
              >
                <tr>
                  <th style={{ width: "12%", textAlign: "center" }}>S No</th>

                  <th style={{ textAlign: "center" }}>Category</th>
                  <th style={{ textAlign: "center" }}>Complaint Details</th>
                  <th style={{ textAlign: "center" }}>Matrimony Id</th>
                  <th style={{ textAlign: "center" }}>Evidence</th>
                 
                </tr>
              </thead>
              <tbody>
                {Array.isArray(report) && report.length > 0 ? (
                  report.map((user, index) => (
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          color: "#000",
                          fontWeight: "500",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {user.abuseCategory}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {user.complaintDetails}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {user.matrimonyId}
                      </td>
                      {user.evidence ? (
                        <td
                          style={{
                            textAlign: "center",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          <img
                            src={process.env.REACT_APP_API + user.evidence}
                            style={{ width: "80px", height: "80px" }}
                            alt="..."
                          />
                        </td>
                      ) : (
                        <td
                          style={{
                            textAlign: "center",
                            textTransform: "capitalize",
                          }}
                        >
                          No Evidence
                        </td>
                      )}
                    
                    </tr>
                  ))
                ) : (
                  <div>
                    <p>No Data Found</p>
                  </div>
                )}
              </tbody>
            </table>
          </section>
        </div>
      </Col>
    </Row>
  );
};

export default Reports;
