import moment from "moment/moment";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container } from "reactstrap";

const Usercard = ({
  id,
  image,
  name,
  age,
  gender,
  dob,
  state,
  status,
  email,
  onDelete,
  onStatus,
  errorButtonText = "Inactive",
}) => {
  return (
    <Col xs={12} md={6} lg={4} sm={12}>
      <div
        className="card"
        style={{
          width: "100%",
          padding: "20px 20px",
        }}
      >
        <Container
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <img
              src={process.env.REACT_APP_API + image}
              style={{ width: "120px", height: "120px" }}
              alt="..."
            />
          </div>
          <div className="card-body">
            <h5 className="card-title" style={{ color: "#eb3143" }}>
              {name}
            </h5>

            <ul className="list">
              <li>Age: {age}</li>
              <li>
                Gender:
                {gender}
              </li>
              <li>DOB: {moment(dob).format("DD-MM-YYYY")}</li>
              <li style={{ textTransform: "capitalize" }}>
                State:
                {state}
              </li>
            </ul>
          </div>
        </Container>

        <div
          className="btns"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Link
            to={"/dashboard1/" + id}
            className="btn"
            style={{
              fontSize: "14px",
              fontWeight: "500",
              background: "green",
              color: "white",
            }}
          >
            <i class="bi bi-eye-fill"></i> View
          </Link>
          <Link
            to=""
            onClick={onDelete}
            className="btn"
            style={{
              fontSize: "14px",
              fontWeight: "500",
              background: "#eb3143",
              color: "white",
            }}
          >
            Delete
          </Link>

          <a
            href="#"
            className="btn"
            style={{
              fontSize: "14px",
              fontWeight: "500",
              background: status == "active" ? "green" : "#00008B",
              color: "white",
            }}
            onClick={onStatus}
          >
            <i className="bi bi-eye-fill"></i>{" "}
            {status === "active" ? "Inactive" : "Active"}
          </a>
        </div>
      </div>
    </Col>
  );
};

export default Usercard;
