import React, { useEffect, useState, useRef } from "react";
import { Form, FormGroup, Label, Input, Row, Col, Container } from "reactstrap";
import "../Banner/banner.css";
import Cropper from "../Cropper";
import api from "../../Axios";
import NotificationAlert from "react-notification-alert";
import { Link } from "react-router-dom";
import Select from "react-select";
const ViewMembership = () => {
  const notiRef = useRef();
  const [data, setData] = useState({
    planName: "",
    // durationType: "",
    amount: "",
  });

  const [membershiplist, setMembershipList] = useState();
  const [render, setRender] = useState(false);

  function deletee(i) {
    api.post("/admin/membership/deleteMembership", { id: i.id }).then(() => {
      api.get("/admin/membership/getMembership").then((res) => {
        setMembershipList(res.data.data);
      });
    });
  }
  useEffect(() => {
    api.get("/admin/membership/viewAllMembership").then((res) => {
      setMembershipList(res.data.data);
    });
  }, [render]);

  return (
    <div>
      <div className="content">
        <div className="notification-popup">
          <NotificationAlert ref={notiRef} />
        </div>
        <Row>
          <Col xs={12} md={12}>
            <Row>
              <div className="container-fluid p-3 mt-5">
                <section className="box ">
                  <table class="table table-sm mt-3">
                    <thead
                      class="thead"
                      style={{ backgroundColor: "black", color: "white" }}
                    >
                      <th
                        style={{
                          textAlign: "center",
                          padding: "10px",

                          fontSize: "16px",
                        }}
                      >
                        S No{" "}
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          padding: "10px",

                          fontSize: "16px",
                        }}
                      >
                        PlanName{" "}
                      </th>
                       <th
                        style={{
                          textAlign: "center",
                          padding: "10px",
                         
                          fontSize: "16px",
                        }}>Accessiable Members </th>

                      <th
                        style={{
                          textAlign: "center",
                          padding: "10px",
                          // width: "20%",
                          fontSize: "16px",
                        }}
                      >
                        Amount
                      </th>

                      <th
                        style={{
                          textAlign: "center",
                          padding: "10px",
                          fontSize: "16px",
                        }}
                      >
                        {" "}
                        Action
                      </th>
                    </thead>
                    <tbody>
                      {Array.isArray(membershiplist) &&
                      membershiplist.length > 0 ? (
                        membershiplist.map((membership, index) => (
                          <tr>
                            <td
                              style={{
                                textAlign: "center",
                                color: "#000",
                                fontWeight: "500",
                              }}
                            >
                              {" "}
                              {index + 1}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                color: "#000",
                                fontWeight: "500",
                              }}
                            >
                              {membership.planName}
                            </td>

                             <td
                              style={{
                                textAlign: "center",
                                color: "#000",
                                fontWeight: "500",
                              }}
                            >
                              {membership.accessMobile}
                            </td> 
                            <td
                              style={{
                                textAlign: "center",
                                color: "#000",
                                fontWeight: "500",
                              }}
                            >
                              {membership.amount}
                            </td>

                            <td style={{ textAlign: "center" }}>
                              <Link to={`/editmembership/${membership.id}`} >
                                <span
                                  className="badge"
                                  style={{
                                    cursor: "pointer",
                                    background: "red",
                                    color: "#fff",
                                  }}
                                >
                                  <span>Edit</span>
                                </span>
                              </Link>
                              <span
                                className="badge "
                                onClick={() => deletee(membership)}
                                style={{
                                  cursor: "pointer",
                                  background: "red",
                                  color: "#fff",
                                }}
                              >
                                <span>Delete</span>
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div
                          style={{ marginLeft: "500px", marginTop: "250px" }}
                        >
                          <h3> Profile Not Found</h3>
                        </div>
                      )}
                    </tbody>
                  </table>
                </section>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ViewMembership;
