import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Typography } from "@mui/material";
import "./style.css";
import api from "../../Axios";

const Feedback = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    api.get("/admin/allUsers/viewAllFeedback").then((res) => {
      setData(res.data.data);
    });
  }, []);
  const columns = [
{

      name: "matrimonyId",
      label: "Matrimony ID",
      options: {
        filter: true,
        sort: true,
        filterOptions: { fullWidth: true },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "category",
      label: "Category",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "suggestions",
      label: "Suggestions",
      options: {
        filter: true,
        sort: false,
        filterOptions: { fullWidth: true },
        customBodyRender: (value) => (
          <Typography fontSize={14} textTransform="capitalize" noWrap>
            {value}
          </Typography>
        ),
      },
    },
  ];

  const options = {
    elevation: 0,
    expandableRows: false,
    filter: false,
    responsive: 'standard',
    selectableRows: false,
    downloadOptions: {
      filename: `vvk_feedback.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true
      }
    }
  };

  return (
    <>
      <div className="container" style={{ marginTop: "10%" }}>
        <div className="row">
          <MUIDataTable
            title={"Feedback"}
            data={data}
            options={options}
            columns={columns}
          />
        </div>
      </div>
    </>
  );
};

export default Feedback;
