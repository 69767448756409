import React, { useEffect, useState, useRef } from "react";
import { Form, FormGroup, Label, Input, Row, Col, Container } from "reactstrap";
import "./ReactQuills";
import "../Banner/banner.css";
import api from "../../Axios";
import { Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import Swal from "sweetalert2";
import { Diamond } from "@mui/icons-material";
const AddMembership = () => {
  const notiRef = useRef();
  const [data, setData] = useState({
    planName: "",
    amount: "",
    accessMobile: "",
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  function handleSubmit(e) {
    e.preventDefault();

    e.currentTarget.reset();

    console.log(data);

    api.post("/admin/membership/addMembership", data).then((res) => {
      console.log(res.data.data);
      setRender(!render);
      setData("");
      Swal.fire({
        text: res.data.data,
      });
    });
  }

  const [membershiplist, setMembershipList] = useState();
  const [render, setRender] = useState(false);

  function deletee(i) {
    api.post("/admin/membership/deleteMembership", { id: i.id }).then((res) => {
      Swal.fire({
        text: res.data.data,
      });
      api.get("/admin/membership/viewAllMembership").then((res) => {
        setMembershipList(res.data.data);
      });
    });
  }
  useEffect(() => {
    api.get("/admin/membership/viewAllMembership").then((res) => {
      setMembershipList(res.data.data);
    });
  }, [render]);
  return (
    <div>
      <div className="content">
        <div className="notification-popup">
          <NotificationAlert ref={notiRef} />
        </div>
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Membership Plan</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h1
                    className="title float-left"
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "500",
                      padding: "50px 50px",
                    }}
                  >
                    Add Membership Plan
                  </h1>
                </header>
                <div className="content-body">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      <Form onSubmit={handleSubmit}>
                        <label>Name</label>
                        <select
                          onChange={handleChange}
                          name="planName"
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Select Plan</option>
                          <option value="Gold">Gold</option>
                          <option value="Platinum">Platinum</option>
                          <option value="Diamond">Diamond</option>
                        </select>
                        {/* <FormGroup>
                          <Label>Plan Name</Label>
                          <Input
                            onChange={handleChange}
                            type="text"
                            name="planName"
                          />
                        </FormGroup> */}
                        {/* <FormGroup>
                          <Label>Description</Label>
                         <ReactQuills 
                           onChange={handleChange}/>
                        </FormGroup>
                         */}

                        <FormGroup>
                          <Label>Amount</Label>
                          <Input
                            type="text"
                            onChange={handleChange}
                            name="amount"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Accessiable Members</Label>
                          <Input
                            type="text"
                            onChange={handleChange}
                            name="accessMobile"
                          />
                        </FormGroup>
                        <FormGroup style={{ marginBottom: "0px" }}>
                          <button
                            type="submit"
                            className="btn "
                            style={{
                              padding: "5px 10px",
                              background: "black",
                              color: "white",
                              fontWeight: "500",
                            }}
                          >
                            Submit
                          </button>
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <h6 className="title">View Membership Plan</h6>
            <Row>
              <div className="container-fluid p-3 mt-5">
                <section className="box ">
                  <table class="table table-sm mt-3">
                    <thead
                      class="thead"
                      style={{ backgroundColor: "black", color: "white" }}
                    >
                      {" "}
                      <tr>
                        <th style={{ width: "12%", textAlign: "center" }}>
                          S No
                        </th>
                        <th style={{ textAlign: "center" }}> Plan Name</th>
                        <th style={{ textAlign: "center" }}>
                          {" "}
                          Accessible Members
                        </th>
                        <th style={{ textAlign: "center" }}> Amount</th>

                        <th style={{ textAlign: "center" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(membershiplist) &&
                      membershiplist.length > 0 ? (
                        membershiplist.map((membership, index) => (
                          <tr>
                            <td
                              style={{
                                textAlign: "center",
                                color: "#000",
                                fontWeight: "500",
                                width: "20px",
                              }}
                            >
                              {" "}
                              {index + 1}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                color: "#000",
                                fontWeight: "500",
                              }}
                            >
                              {membership.planName}
                            </td>

                            <td
                              style={{
                                textAlign: "center",
                                color: "#000",
                                fontWeight: "500",
                              }}
                            >
                              {membership.accessMobile}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                color: "#000",
                                fontWeight: "500",
                              }}
                            >
                              {membership.amount}
                            </td>

                            <td style={{ textAlign: "center" }}>
                              <Link to={`/editmembership/${membership.id}`}>
                                <span
                                  className="badge"
                                  style={{
                                    cursor: "pointer",
                                    background: "red",
                                    color: "#fff",
                                  }}
                                >
                                  <span>Edit</span>
                                </span>
                              </Link>
                              <span
                                className="badge "
                                onClick={() => deletee(membership)}
                                style={{
                                  cursor: "pointer",
                                  background: "red",
                                  color: "#fff",
                                }}
                              >
                                <span>Delete</span>
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div>
                          <p>No Data Found</p>
                        </div>
                      )}
                    </tbody>
                  </table>
                </section>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddMembership;
