import React, { useEffect, useState, useRef } from "react";
import { Form, FormGroup, Label, Input, Row, Col, Container } from "reactstrap";
import "../Banner/banner.css";
import Cropper from "../Cropper";
import api from "../../Axios";
import NotificationAlert from "react-notification-alert";
import { Link } from "react-router-dom";
import Select from "react-select";
const Viewads = () => {
  const notiRef = useRef();
  const [data, setData] = useState({
    name: "",
    adLinkk: "",
    adLevel: "",
    image: "",
    contactPerson: "",
    phone: "",
    date: "",
  });
  const [adslist, setAdslist] = useState([]);
  const [render, setRender] = useState(false);

  function deletee(i) {
    console.log(i);
    api.post("/admin/adminDashboard/deleteAd", { id: i.id }).then(() => {
      api.get("/admin/adminDashboard/viewAd").then((res) => {
        setAdslist(res.data.data);
      });
    });
  }
  useEffect(() => {
    api.get("/admin/adminDashboard/viewAd").then((res) => {
      setAdslist(res.data.data);
    });
  }, [render]);



  return (
    <div>
      <div className="content">
        <div className="notification-popup">
          <NotificationAlert ref={notiRef} />
        </div>
        <Row>
          <Col xs={12} md={12}>
            <Row>
              <div className="container-fluid p-3 mt-5">
                <section className="box ">
                  <table class="table table-sm mt-3">
                    <thead
                      class="thead"
                      style={{ backgroundColor: "black", color: "white" }}
                    >
                    <th style={{ width: "12%", textAlign: "center" }}>S No </th>
                             <th
                        style={{
                          textAlign: "center",
                          
                       
                          
                        }}>Name </th>
                      <th
                        style={{
                          textAlign: "center",
                          
                         
                          
                        }}>Ads Link </th>
                            
                      <th
                        style={{
                          textAlign: "center",
                          
                          
                        }}
                      >
                        Ads Image{" "}
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          
                          
                        }}>Contact Name</th>
                      <th
                        style={{
                          textAlign: "center",
                          
                          
                        }}>Ph. No </th>
                         <th
                        style={{
                          textAlign: "center",
               
                          
                        }}>Date</th>
                      {/* <th style={{ textAlign: "left",padding:"10px" }}> Product Type</th>
                      <th style={{ textAlign: "left",padding:"10px" }}> Status</th> */}
                      <th
                        style={{
                          textAlign: "center",
                          
                          
                        }}
                      >
                        {" "}
                        Action
                      </th>
                    </thead>
                    <tbody>
                      {Array.isArray(adslist) && adslist.length > 0 ? (
                        adslist.map((adss, index) => (
                          <tr>
                            <td
                              style={{
                                textAlign: "center",
                                color: "#000",
                                fontWeight: "500",
                              }}
                            >
                              {" "}
                              {index + 1}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                color: "#000",
                                fontWeight: "500",textTransform: "capitalize"
                              }}
                            >
                              {adss.name}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                color: "#000",
                                fontWeight: "500",textTransform: "capitalize"
                              }}
                            >
                              {adss.link}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <img
                                src={process.env.REACT_APP_API + adss.image}
                                style={{ height: "100px", width: "150px" }}
                              />
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                color: "#000",
                                fontWeight: "500",textTransform: "capitalize"
                              }}
                            >
                              {adss.contactPerson}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                color: "#000",
                                fontWeight: "500",
                              }}
                            >
                              {adss.phone}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                color: "#000",
                                fontWeight: "500",
                              }}
                            >
                              {adss.date}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className="badge "
                                onClick={() => deletee(adss)}
                                style={{
                                  cursor: "pointer",
                                  background: "red",
                                  color: "#fff",
                                }}
                              >
                                <span>Delete</span>
                              </span>
                            </td>
                           
                          </tr>
                        ))
                      ) : (
                        <div >
                        <p> 
                      No data Found</p>
                        </div>
                      )}
                    </tbody>
                  </table>
                </section>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Viewads;
