import React, { useEffect, useState, useRef } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import "./ReactQuills";
import "../Banner/banner.css";
import api from "../../Axios";
import NotificationAlert from "react-notification-alert";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

const EditMembershipPlan = () => {
  const notiRef = useRef();
  const [data, setData] = useState({
    id:"",
    planName: "",
    accessMobile:"",
    amount: "",
  });

  const [render, setRender] = useState(false);



  const { id } = useParams();

  function handleSubmit(e) {
    e.preventDefault();
    console.log("handleSubmit called with data:", data);

    api.post("/admin/membership/updateMembership", data).then((res) => {
      console.log(res.data.data);
      setRender(!render);
      Swal.fire(
        'Updated!',
        'Membership plan has been updated.',
        'success'
      )
    });
  }

  useEffect(() => {
    api
      .post("/admin/membership/getMembership", {
        id: id,
      })
      .then((res) => {
        setData(res.data.data);
        
      });

  }, []);

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  console.log(data)

  return (
    <div>
      <div className="content">
        <div className="notification-popup">
          <NotificationAlert ref={notiRef} />
        </div>
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Membership Plan</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h1
                    className="title float-left"
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "500",
                      padding: "50px 50px",
                    }}
                  >
                    Edit Membership Plan
                  </h1>
                </header>
                <div className="content-body">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      <Form onSubmit={handleSubmit}>
                        <label>Name</label>
                        <select
                          onChange={handleInputChange}
                          value={data.planName}
                          name="planName"
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option value="">Select Plan</option>
                          <option value="Gold">Gold</option>
                          <option value="Platinum">Platinum</option>
                          <option value="Diamond">Diamond</option>
                        </select>
                   
                        <FormGroup>
                        <Label>Accessiable Mobile</Label>
                        <Input
                          type="text" 
                          onChange={handleInputChange}
                          defaultValue={data.accessMobile}
                          name="amount"
                        />
                      </FormGroup>     <FormGroup>
                          <Label>Amount</Label>
                          <Input
                            type="text" 
                            onChange={handleInputChange}
                            defaultValue={data.amount}
                            name="amount"
                          />
                        </FormGroup>

                        <FormGroup style={{ marginBottom: "0px" }}>
                          <button
                            type="submit"
                            className="btn "
                            style={{
                              padding: "5px 10px",
                              background: "black",
                              color: "white",
                              fontWeight: "500",
                            }}
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditMembershipPlan;
